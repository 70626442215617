<template>
  <div class="manage-category">
    <el-row type="flex" align="middle" justify="space-between" class="mb-l">
      <el-row type="flex" align="middle" class="page-title">
        <i class="el-icon-menu mr-s" />
        <h3>Quản lý bài viết</h3>
      </el-row>
      <el-button type="primary" @click="$router.push({ name: 'CreatePosts' })">
        Thêm
      </el-button>
    </el-row>
    <el-input
      v-model="name"
      @input="handleSearchPosts"
      placeholder="Tìm kiếm bài viết"
      class="search mb-xl"
    />
    <el-table :data="postses" border v-loading="callingAPI">
      <el-table-column label="#" type="index" align="center" width="50px" />
      <el-table-column label="Tên bài viết" min-width="300px">
        <template slot-scope="scope"> {{ scope.row.name }} </template>
      </el-table-column>
      <el-table-column label="Loại" min-width="150px">
        <template slot-scope="scope">
          {{
            currentCategory(scope.row.category_id)
              ? currentCategory(scope.row.category_id).name
              : ""
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="Tên tác giả">
        <template> hau1234 </template>
      </el-table-column> -->
      <!-- <el-table-column label="Điểm" width="80px" align="center">
        <template> 5 </template>
      </el-table-column> -->
      <el-table-column label="Ngày tạo" width="100px">
        <template slot-scope="scope">
          {{ scope.row.created_at | convertDate }}
        </template>
      </el-table-column>
      <el-table-column label="Ngày xuất bản" width="120px">
        <template slot-scope="scope">
          {{ scope.row.published_at | convertDate }}
        </template>
      </el-table-column>
      <el-table-column label="Ngày cập nhật" width="120px">
        <template slot-scope="scope">
          {{ scope.row.updated_at | convertDate }}
        </template>
      </el-table-column>
      <el-table-column label="Sửa" align="center" width="70px" fixed="right">
        <template slot-scope="scope">
          <el-button
            plain
            size="small"
            @click="
              $router.push({
                name: 'UpdatePosts',
                params: { id: scope.row.id },
              })
            "
            icon="el-icon-edit"
            circle
          />
        </template>
      </el-table-column>
      <el-table-column label="Xoá" align="center" width="70px" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="small"
            @click="handeRemovePosts(scope.row)"
            icon="el-icon-delete"
            circle
          />
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end" class="mt-m">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="page"
        @current-change="handleChangePage"
      />
    </el-row>
  </div>
</template>

<script>
import { fetchCategory } from "@/services/category";
import { searchPosts, deletePosts } from "@/services/posts";
export default {
  name: "ManagePosts",
  data() {
    return {
      callingAPI: false,
      debounce: null,

      name: "",
      page: 1,
      total: 0,
      pageSize: 10,

      postses: [],
      categories: [],
    };
  },
  async beforeMount() {
    await this.loadCategories();
    await this.loadData();
  },
  methods: {
    async loadCategories() {
      try {
        this.callingAPI = true;
        const response = await fetchCategory();
        this.categories = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async loadData(page = null) {
      try {
        this.callingAPI = true;
        const params = {
          name: this.name,
          page: page ? page : 1,
          limit: this.pageSize,
        };
        const response = await searchPosts(params);
        this.postses = response.data.posts;
        this.total = response.data.paginate.total;
        this.callingAPI = false;
      } catch (error) {
        console.log(error);
        this.callingAPI = false;
      }
    },

    async handleChangePage(page) {
      this.page = page;
      await this.loadData(page);
    },

    async handleSearchPosts() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.loadData();
      }, 500);
    },

    async handeRemovePosts(row) {
      this.$confirm(`Chắc chắn muốn xoá bài viết ${row.name} ?`, "Warning", {
        confirmButtonText: "Xoá",
        cancelButtonText: "Huỷ",
        type: "warning",
      })
        .then(async () => {
          try {
            this.callingAPI = true;
            await deletePosts(row.id);
            await this.loadData(this.page);
            this.$notify.success({
              title: "Thành công",
              message: `Xoá bài viết ${row.name} thành công.`,
              duration: 2000,
            });
          } catch (error) {
            this.callingAPI = false;
            this.$notify.error({
              title: "Thất bại",
              message: "Xoá bài viết thất bại.",
              duration: 2000,
            });
          }
        })
        .catch(() => {});
    },

    currentCategory(category_id) {
      if (this.categories.find((c) => c.id == category_id)) {
        return this.categories.find((c) => c.id == category_id);
      }
      return null;
    },
  },
};
</script>
